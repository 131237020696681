/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {
  A,
  AnimateOnEnter,
  Block,
  Constrain,
  FlyoutMenu,
  H1,
  Header,
  IconDribbble,
  IconGithub,
  IconLinkedin,
  IconGroup,
  P,
  SiteLayout,
  Strong,
  Text,
} from '@ebinion/zeke-design-system'

import Contact from './Contact'
import Seo from './Seo'
import { AppContext } from '../state'
import { openContactModal } from '../state/actions'

export default function Layout(props) {
  const { children, description, footerChildren, hasFloatedHeader, title } = props

  const appContext = useContext(AppContext)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const openMenuHandler = () => setIsMenuOpen(true)
  const closeMenuHandler = () => setIsMenuOpen(false)

  const handleOpenContact = event => {
    event.preventDefault()
    appContext.dispatch(openContactModal())
  }

  return (
    <>
      <Seo title={title} description={description} />
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/iuy7ikv.css" />
      </Helmet>
      <SiteLayout
        headerChildren={
          <Header
            isFloated={hasFloatedHeader}
            menuClickHandler={openMenuHandler}
            siteTitle="Studio"
            logoTo="/"
            logoLinkElement={Link}
          >
            <A onClick={handleOpenContact}>Contact</A>
          </Header>
        }
        footerChildren={
          <>
            {footerChildren}
            <Block padding="xl" color="dark">
              <Constrain isCentered limit="text">
                <Block padding="xl">
                  <AnimateOnEnter kind="fadeInFromBottom">
                    <H1 as="h2" color="knockout" align="center">
                      Start the Conversation
                    </H1>
                    <P color="knockout" align="center">
                      Have a question? Want to start a project or work together? Please don&rsquo;t
                      hesistate to reach out.
                    </P>
                    <P align="center" color="knockout">
                      <A onClick={handleOpenContact}>Let&rsquo;s work together</A>
                    </P>
                  </AnimateOnEnter>
                </Block>
                <Block isInset padding="xl" paddingBottom="0" paddingTop="100px">
                  <P align="center" size="xs" color="knockout">
                    <Strong color="knockout">
                      &copy; {new Date().getFullYear()} Ezekiel &ldquo;Zeke&rdquo; Binion,
                    </Strong>{' '}
                    product designer, developer, and AIPMM certified product manager
                  </P>
                </Block>
              </Constrain>
            </Block>
          </>
        }
      >
        {children}
      </SiteLayout>
      <FlyoutMenu
        closeHandler={closeMenuHandler}
        isOpen={isMenuOpen}
        items={[
          {
            to: '/',
            label: 'Home',
            logoLinkElement: Link,
          },
          {
            to: 'https://www.dropbox.com/s/v3yiiogq14wz94w/Zeke-Binion-resume-2022-Jan-Product-Design-Director-web.pdf?dl=0',
            label: 'Résumé',
          },
          {
            to: 'https://zekebinion.io',
            label: 'Blog',
          },
        ]}
        footer={
          <IconGroup>
            <A href="https://dribbble.com/ebinion" kind="incognito">
              <IconDribbble />
              <Text visuallyHidden>Zeke&rsquo;s Dribbble Profile</Text>
            </A>
            <A href="https://www.linkedin.com/in/ezekielbinion/" kind="incognito">
              <IconLinkedin />
              <Text visuallyHidden>Zeke&rsquo;s Linkedin Profile</Text>
            </A>
            <A href="https://github.com/ebinion" kind="incognito">
              <IconGithub />
              <Text visuallyHidden>Zeke&rsquo;s Github Profile</Text>
            </A>
          </IconGroup>
        }
        portalQuery="#portal"
      />
      <Contact />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  footerChildren: PropTypes.node,
  hasFloatedHeader: PropTypes.bool,
  title: PropTypes.string,
}

Layout.defaultProps = {
  description: null,
  footerChildren: null,
  hasFloatedHeader: false,
  title: null,
}
