import React, { useState, useContext, useRef, useEffect } from 'react' // eslint-disable-line no-unused-vars
import styled from 'styled-components'
import { debounce } from 'lodash'
import { Block, Button, H1, P, Modal } from '@ebinion/zeke-design-system'

import { AppContext } from '../state'
import { closeContactModal } from '../state/actions'

const Thingy = styled.div`
  align-items: center;
  background: var(--c-gray-xx-light);
  display: flex;
  justify-content: center;
  padding: calc(var(--component-padding) / 2);
  padding-left: var(--component-padding);
  margin-top: var(--component-margin);
`

const Pre = styled.pre`
  flex: 0 1 100%;
  margin: 0;
`

function Contact() {
  const appContext = useContext(AppContext)
  const [showSuccess, setShowSuccess] = useState(false)
  const clipboard = useRef()

  const hideSuccess = debounce(() => {
    setShowSuccess(false)
  }, 2000)

  const handleCopy = event => {
    event.target.blur()

    if (clipboard.current) {
      clipboard.current.writeText('zeke@binion.io').then(() => {
        setShowSuccess(true)
        hideSuccess()
      })
    }
  }

  useEffect(() => {
    clipboard.current = navigator.clipboard
  }, [])

  return (
    <Modal
      id="contactModal"
      isOpen={appContext.state.isContactModalOpen}
      handleClose={() => appContext.dispatch(closeContactModal())}
      size="window"
      portalTarget="#portal"
    >
      <Block padding="xl">
        <H1 as="h2">Get in Touch</H1>
        <P>
          I don&rsquo;t know about you but I think forms are a poor way to get in touch with people.
          Instead, please feel free to copy and paste my email address into your email client of
          choice.
        </P>
        <Thingy>
          <Pre as={P}>
            <code>zeke@binion.io</code>
          </Pre>
          {clipboard.current && (
            <Button onClick={handleCopy} disabled={showSuccess ? true : undefined}>
              {showSuccess ? 'Copied!' : 'Copy'}
            </Button>
          )}
        </Thingy>
      </Block>
    </Modal>
  )
}

export default Contact
