import styled from 'styled-components'

import { animationTokens } from '@ebinion/zeke-design-system'

const Rounded = styled.div`
  border-radius: 8px;
  line-height: 0;
  overflow: hidden;

  a & > * {
    transition: transform ${animationTokens.duration.normal} ${animationTokens.easing};
  }

  a:hover & > * {
    transform: scale(1.2);
  }
`

export default Rounded
